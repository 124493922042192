<style>
    .extra_info .el-form-item__label {
        font-size: 12px;
        line-height: 20px;
        width: 140px;
    }

    .extra_info .el-form-item {
        margin-bottom: 0px;
    }

    .extra_info .el-form-item__content {
        line-height: 20px;
        font-size: 12px;
    }
</style>
<template>
  <div class="main-content by-banners padding-md-bottom padding-md-top">
    <div >
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <label>{{ $t('PayStatus')}}</label>:
          <el-select size="mini" v-model="queryForm.pay_status" clearable :placeholder="$t('All')">
            <el-option key="" value="" :label="$t('All')">
            </el-option>
            <el-option key="0" value="0" :label="$t('NotPaid')">
            </el-option>
            <el-option key="1" value="1" :label="$t('Paid')">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <label>{{ $t('OrderStatus') }}</label>:
          <el-select
            v-model="queryForm.order_status"
            size="mini"
            clearable
            :placeholder="$t('All')"
          >
            <el-option
              key=""
              value=""
              :label="$t('All')"
            />
            <el-option
              key="0"
              value="0"
              label="待发货"
            />
            <el-option
                    key="2"
                    value="2"
                    label="待收货"
            />
            <el-option
              key="1"
              value="1"
              label="已完成"
            />
            <el-option
              key="n1"
              :value="-1"
              :label="$t('Canceled')"
            />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="queryForm.mobile"
            size="mini"
            :placeholder="$t('Mobile')"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="refresh()"
          >
            {{
              $t('Search') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>

    <div
      class="grid-content "
    >
      <el-table
        ref="table"
        v-loading="loading"
        :data="tableData"
        stripe
        :element-loading-text="$t('Loading')"
        style="width: 100%"
      >
        <el-table-column
          width="180px"
          :label="$t('OrderCode')"
        >
          <template #default="scope">
            {{ scope.row.order_no }}
          </template>
        </el-table-column>
        <el-table-column
                width="160px"
                :label="$t('Nickname')"
        >
          <template #default="scope">
            {{ scope.row.nick }}
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          :label="$t('Mobile')"
        >
          <template #default="scope">
            {{ scope.row.user_mobile }}
          </template>
        </el-table-column>
        <el-table-column
                width="120px"
                :label="$t('Money')"
        >
          <template #default="scope">
            {{ scope.row.total_price/100 }} {{ $t('Unit.Yuan') }}<br>
            {{ scope.row.pay_price/100 }} {{ $t('Unit.Yuan') }}
          </template>
        </el-table-column>
        <el-table-column
                width="140px"
                :label="$t('CreateTime')"
        >
          <template #default="scope">
            {{ (new Date(scope.row.create_time * 1000)).format('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          :label="$t('Paid')"
        >
          <template #default="scope">
            {{ $t(scope.row.pay_status.toString()) }}

<!--            <el-button-->
<!--                    size="mini"-->
<!--                    @click="onVerify(scope.row)"-->
<!--            >-->
<!--              审查支付信息-->
<!--            </el-button>-->
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          :label="$t('OrderStatus')"
        >
          <template #default="scope">
            <span v-if="scope.row.order_status==0"> 待发货</span>
            <span v-if="scope.row.order_status==1">已完成</span>
            <span v-if="scope.row.order_status==-1">{{ $t('Canceled') }}</span>
            <span v-if="scope.row.order_status==2">待收货</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('Action')"
        >
          <template #default="scope">

            <el-button
                    size="mini"
                    @click="onView(scope.row)"
            >
              {{ $t('View') }}
            </el-button>

            <el-button
                    :disabled="scope.row.order_status !== 0"
                    size="mini"
                    @click="onSetOut(scope.row)"
            >
              {{ $t('Delivery') }}
            </el-button>


            <el-button
                    size="mini"
                    :loading="exportLoading"
                    :disabled="scope.row.pay_status !== 1"
                    @click="onExport(scope.row)"
            >
              导出
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <el-pagination
          :current-page="queryForm.page_index"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryForm.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
          @size-change="byPagerSizeChange"
          @current-change="byPagerCurrentChange"
        />
      </div>
    </div>

    <el-dialog
      v-model="dialogGiveVisible"
      :show-close="false"
      :append-to-body="false"
      :title="$t('Delivery')"
    >
      <el-form
        label-position="right"
        label-width="100px"
      >
        <el-form-item>
          是否确认发货？
        </el-form-item>
      </el-form>
      <template #footer>
        <div
                class="dialog-footer"
        >
        <el-button @click="dialogGiveVisible = false">
          {{ $t('Cancel') }}
        </el-button>
        <el-button
          :loading="loading"
          type="primary"
          @click="sureSetOut()"
        >
          {{ $t('Confirm') }}
        </el-button>
      </div>
      </template>
    </el-dialog>


    <el-dialog
            v-model="dialogVerifyVisible"
            :show-close="false"
            :append-to-body="false"
            :title="$t('View')"
    >
      <div v-if="viewLoading">
        <i class="el-icon-loading" style="margin-left: 100px;font-size: 20px;" ></i>
      </div>
      <el-form
              v-else
              label-position="right"
              label-width="100px"
      >
        <el-form-item label="总价">
          {{ (viewForm.total_price/100.0).toFixed(2)}} 元
        </el-form-item>
        <el-form-item  label="用户">
          [{{getAccountType(viewForm.account_type)}}] {{viewForm.nick}} , {{viewForm.user_mobile}}
        </el-form-item>
        <el-form-item  label="地址">
          {{viewForm.address_info.name}} , {{viewForm.address_info.mobile}} , {{viewForm.address_info.detail}}
        </el-form-item>
        <el-form-item>
          <el-table
                  :data="viewForm.items"
          >
            <el-table-column label="标题">
              <template #default="scope">
                {{scope.row.item_title}},{{scope.row.item_sku_title}}
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template #default="scope">
                {{scope.row.item_count}}
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template #default="scope">
                {{(scope.row.item_sku_price/100.0).toFixed(2)}}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item
                  label="状态日志"
          >
            <el-table
                    :data="viewForm.his"
                    stripe
                    style="width: 100%"
            >
              <el-table-column
                      width="120px"
                      :label="$t('CreateTime')">
                <template #default="scope">
                  {{(new Date(scope.row.create_time * 1000)).format('yyyy-MM-dd hh:mm:ss')}}
                </template>
              </el-table-column>
              <el-table-column
                      :label="$t('Note')">
                <template #default="scope">
                  {{scope.row.note}}
                </template>
              </el-table-column>
            </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
          <el-button
                  size="mini"
                  :loading="loading"
                  @click="dialogVerifyVisible=false"
          >
            确定
          </el-button>

        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dtOrderApi from '../../api/dtOrderApi'

export default {
  components: {
  },
  data () {
    return {
      exportLoading: false,
      notifyHistory: [],
      viewLoading: true,
      dialogVerifyVisible: false,
      dialogGiveVisible: false,
      queryForm: {
        order_no: '',
        mobile: '',
        order_status: '',
        pay_status: '',
        page_index: 1,
        page_size: 10
      },
      viewForm: {
        address_info: {
          name: '',mobile:'',detail:''
        }
      },
      rules: {
        title: [
          { required: true, message: this.$i18n.t('Please Input Title'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$i18n.t('String Length Between', [1, 32]), trigger: 'blur' }
        ]
      },
      order_id: '',
      user_id: '',
      count: 0,
      tableData: [],
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created () {

  },
  mounted () {
    this.refresh()
  },
  methods: {
    onExport(row) {
      if (row.pay_status === 0) {
        tools.alertError('未支付订单无法导出');
        return ;
      }
      // row.id = 100000;
      let xhr = new XMLHttpRequest();
      //post方式请求后台的路径
      xhr.open('post', tools.getApiUrl() + '/100/DtOrder/export', true);
      //导出的Excel是二进制数据类型，所以设置为blob
      xhr.responseType = 'blob';
      //请求头（key,value），请求头可以设置多个key-value对
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      //返回成功，导出的Excel文件
      this.exportLoading = true;
      let that = this;
      xhr.onload = function () {
        that.exportLoading = false;
        if (this.status === 200) {
          if (this.response.type  === 'application/json') {
            let reader = new FileReader();
            reader.readAsText(this.response, 'utf-8');
            reader.onload = function () {
              let data = JSON.parse(reader.result.toString());
              console.debug(data);
              if (!data.msg) {
                tools.alertError('导出失败!');
              } else {
                tools.alertError(data.msg);
              }
            }
          } else {
            console.debug(xhr, xhr.responseType);
            let blob = this.response;
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            //设置文件名称
            a.download = (new Date()).getTime() + '.xlsx';
            a.click();
          }
        }
      };
      let formData = new FormData();
      formData.append('order_id', row.id);
      formData.append('client_id', tools.getClientId());
      formData.append('app_version', '1.0.0');
      formData.append('app_type', 'admin');

      xhr.send(formData);
    },
    getAccountType(aType) {
      switch (aType) {
        case "direct":
          return '直营';
        case "franchise":
          return "加盟";
        default:
          return "其它";
      }
    },
    onView(row) {
      this.dialogVerifyVisible = true;
      this.viewLoading = true;
      let that = this;
      dtOrderApi.infoEvidence({id: row.id}).then((data) => {
        that.viewForm = Object.assign(that.viewForm, data);
        that.viewForm.address_info = JSON.parse(that.viewForm.address_info);
        console.debug(that.viewForm)
      }).catch((msg) => {
        window.tools.alertError(msg);
        that.dialogVerifyVisible = false;
      }).finally(() => {
        that.viewLoading = false;
      });
    },
    pass() {},
    deny() {},
    onVerify(row) {
      this.dialogVerifyVisible = true;
    },
    getPayType (payType) {
      switch (payType) {
        case 'alipay_pc':
          return this.$i18n.t('PcAliPay')
        case 'alipay_wap':
          return this.$i18n.t('WapAliPay')
        case 'wxpay_mini':
          return this.$i18n.t('WxappMini')
        case 'fake':
          return this.$i18n.t('Fake')
        default:
          return payType
      }
    },
    byPagerSizeChange (val) {
      this.queryForm.page_size = val
      this.refresh()
    },
    byPagerCurrentChange (val) {
      this.queryForm.page_index = val
      this.refresh()
    },
    async refresh () {
      // 刷新当前
      this.loading = true
      let that = this
      try {
        let data = await dtOrderApi.query(that.queryForm)
        that.tableData = data.list
        that.count = parseInt(data.count)
        that.loading = false
      } catch (err) {
        console.debug(err)
        window.tools.alertError(err)
        that.loading = false
      }
    },
    onSetOut (row) {
      this.order_id = row.id
      this.user_id = row.user_id
      this.dialogGiveVisible = true
    },
    sureSetOut () {
      this.loading = true
      let that = this
      dtOrderApi.setOut({ id: this.order_id, user_id: this.user_id }).catch((err) => {
        tools.alertError(err.message);
      }).then(function () {
        that.refresh()
      }).finally(function () {
          that.loading = false
          that.dialogGiveVisible = false
      })
    }
  }
}
</script>
