<template>
  <div
      v-loading.body="pageLoading"
      class="main-content"
  >
    <div>
      <el-form
          :inline="true"
          :model="goodsForm"
          class="demo-form-inline"
      >

        <el-form-item>
          <el-input
              size="mini"
              placeholder="用户手机号"
              v-model="mobile">
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
              size="mini"
              v-model="dateRange"
              type="daterange"
              format="YYYY-MM-DD"
              value-format="YYYYMMDD"
              :disabled-date="disabledDate"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button
              :loading="loading"
              type="primary"
              size="mini"
              icon="el-icon-search"
              @click="refresh()"
          >
            {{ $t('Search') }}
          </el-button>
          <el-button
              :loading="exportLoading"
              type="primary"
              size="mini"
              @click="onExport()"
          >
            导出
          </el-button>
          <span> 统计只能查询昨日的数据，间隔最长不超过60天</span>
        </el-form-item>
      </el-form>
    </div>
    <div>
      <el-table
          v-loading="loading"
          :data="tableData"
          style="width: 100%">
        <el-table-column
            prop="title"
            label="商品名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="sku_title"
            label="规格"
            width="180">
        </el-table-column>
        <el-table-column
            label="单价"
            width="180">
          <template #default="scope">
            {{ (scope.row.price / 100.0).toFixed(2) }}
          </template>
        </el-table-column>
        <el-table-column
            prop="sale"
            width="180"
            label="销售数量">
        </el-table-column>
        <el-table-column
            width="180"
            prop="in_stock"
            label="生产">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import dtStockHisApi from "../../api/dtStockHisApi";
import dtOrderApi from "../../api/dtOrderApi";

export default {
  data() {
    return {
      mobile: '',
      exportLoading: false,
      pageLoading: false,
      requestSiteUpdateData: false,
      siteUpdateData: [],
      newUsers: 0,
      newBooks: 0,
      newArticle: 0,
      newVideo: 0,
      goodsForm: {
        start_day: 0,
        end_day: 0,
        page_size: 1000,
      },
      tableData: [],
      dateRange: [],
      loading: false,
    }
  },
  computed: {},
  mounted() {
    let yesterday = new Date((new Date().getTime()));// - 24 * 3600 * 1000));

    this.dateRange = [yesterday.format('yyyyMMdd'), yesterday.format('yyyyMMdd')];
    // 这里获取数据
    this.refresh()
  },
  methods: {
    exportOrderByMobile() {

      tools.JSonToCSV.setDataConvert({
        data: this.tableData,
        fileName: (new Date().getTime()),
        columns: {
          title: ['商品名', '规格', '单价', '销售数量'],
          key: ['title', 'sku_title','price', 'sale'],
          formatter: function (n, v) {
            if (n === 'in_stock') return '';
            if (n === 'price' && !isNaN(Number(v))) {
              return (v  / 100.0).toFixed(2);
            }
          }
        }
      });
    },
    onExport() {
      if (this.mobile.length > 0) {
        // tools.alertError('不支持导出');
        this.exportOrderByMobile();
        return;
      }
      let xhr = new XMLHttpRequest();
      //post方式请求后台的路径
      xhr.open('post', tools.getApiUrl() + '/100/DtStockHistory/export', true);
      //导出的Excel是二进制数据类型，所以设置为blob
      xhr.responseType = 'blob';
      //请求头（key,value），请求头可以设置多个key-value对
      // xhr.setRequestHeader('Content-Type', 'multipart/form-data');
      //返回成功，导出的Excel文件
      this.exportLoading = true;
      let that = this;
      xhr.onload = function () {
        that.exportLoading = false;
        if (this.status === 200) {
          if (this.response.type === 'application/json') {
            let reader = new FileReader();
            reader.readAsText(this.response, 'utf-8');
            reader.onload = function () {
              let data = JSON.parse(reader.result.toString());
              console.debug(data);
              if (!data.msg) {
                tools.alertError('导出失败!');
              } else {
                tools.alertError(data.msg);
              }
            }
          } else {
            console.debug(xhr, xhr.responseType);
            let blob = this.response;
            let a = document.createElement('a');
            let url = window.URL.createObjectURL(blob);
            a.href = url;
            //设置文件名称
            a.download = (new Date()).getTime() + '.xlsx';
            a.click();
          }
        }
      };
      let formData = new FormData();
      formData.append('start_day', this.goodsForm.start_day);
      formData.append('end_day', this.goodsForm.end_day);
      formData.append('client_id', tools.getClientId());
      formData.append('app_version', '1.0.0');
      formData.append('app_type', 'admin');

      xhr.send(formData);
    },
    disabledDate(time) {
      let yesterday = new Date((new Date().getTime() - 0 * 24 * 3600 * 1000)).format("yyyy-MM-dd");

      return time.getTime() >= ((new Date(yesterday)).getTime());
    },

    // 获取数据 必须要要有个loadData方法以供刷新使用
    async refresh() {
      console.log(this.dateRange);
      // if (this.pageLoading) return;
      let that = this
      // this.requestSiteUpdateData = true
      // staticsApi.query({ page_size: 10 }, function (data) {
      //   that.siteUpdateData = data
      //   that.requestSiteUpdateData = false
      //   that.setYesterdayValue(data)
      // }, function (err) {
      //   that.requestSiteUpdateData = false
      // })
      try {

        this.loading = true;

        if (this.mobile.length > 0) {
          let postForm = {
            start_time: this.dateRange[0],
            end_time: this.dateRange[1],
            mobile: this.mobile
          };
          let data = await dtOrderApi.queryByUser(postForm);
          console.debug("商品统计: ", data);
          this.tableData = data;
        } else {
          this.goodsForm.start_day = this.dateRange[0];
          this.goodsForm.end_day = this.dateRange[1];
          let data = await dtStockHisApi.staticsGoods(this.goodsForm);
          console.debug("商品统计: ", data);
          this.tableData = data;
        }
      } catch (err) {
        tools.alertError(err);
      } finally {
        this.loading = false;
      }
    },

    setYesterdayValue(data) {
      let tempDate = (new Date()).getTime() - 24 * 3600 * 1000
      let yesterday = parseInt((new Date(tempDate).format('yyyyMMdd')))
      for (var k = 0; k < data.length; k++) {
        if (data[k].a_date === yesterday) {
          this.newUsers = data[k].new_users
          break
        }
      }
    },
  }
}
</script>
