<style>
    .extra_info .el-form-item__label {
        font-size: 12px;
        line-height: 20px;
        width: 140px;
    }

    .extra_info .el-form-item {
        margin-bottom: 0px;
    }

    .extra_info .el-form-item__content {
        line-height: 20px;
        font-size: 12px;
    }
</style>
<template>
  <div class="main-content by-banners padding-md-bottom padding-md-top">
    <div>
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline"
      >
        <el-form-item>
          <label>{{ $t('PayStatus')}}</label>:
          <el-select size="mini" v-model="queryForm.verify_status" clearable :placeholder="$t('All')">
            <el-option key="" value="" :label="$t('All')">
            </el-option>
            <el-option key="0" value="0" label="待审核">
            </el-option>
            <el-option key="0" :value="-1" label="已驳回">
            </el-option>
            <el-option key="1" value="1" label="已通过">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
                  v-model="queryForm.order_no"
                  size="mini"
                  placeholder="订单号"
                  clearable
          />
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="queryForm.mobile"
            size="mini"
            :placeholder="$t('Mobile')"
            clearable
          />
        </el-form-item>
        <el-form-item>
          <el-button
            :loading="loading"
            type="primary"
            size="mini"
            icon="el-icon-search"
            @click="refresh()"
          >
            {{
              $t('Search') }}
          </el-button>
        </el-form-item>
      </el-form>
    </div>
<!--    <div v-else>-->

<!--      <el-button-->
<!--              type="primary"-->
<!--              size="mini"-->
<!--              @click="back()"-->
<!--      >-->
<!--        {{-->
<!--        $t('Back') }}-->
<!--      </el-button>-->
<!--    </div>-->

    <div
      class="grid-content "
    >
      <el-table
        ref="table"
        v-loading="loading"
        :data="tableData"
        stripe
        :element-loading-text="$t('Loading')"
        style="width: 100%"
      >
        <el-table-column
                width="180px"
                :label="$t('OrderCode')"
        >
          <template #default="scope">
            {{ scope.row.order_no }}
          </template>
        </el-table-column>
        <el-table-column
                width="200px"
                label="图片"
        >
          <template #default="scope">
            <a :href="scope.row.pictures" target="_blank" >
            <el-image :src="scope.row.pictures" style="width:120px;"></el-image>
            </a>
          </template>
        </el-table-column>
        <el-table-column
                width="140px"
                :label="$t('CreateTime')"
        >
          <template #default="scope">
            {{ (new Date(scope.row.create_time * 1000)).format('yyyy-MM-dd hh:mm:ss') }}
          </template>
        </el-table-column>
        <el-table-column
          width="120px"
          :label="$t('Status')"
        >
          <template #default="scope">
            <span v-if="scope.row.verify==0">待审核</span>
            <span v-if="scope.row.verify==1">已通过</span>
            <span v-if="scope.row.verify==-1">已驳回</span>
          </template>
        </el-table-column>
        <el-table-column
          :label="$t('Action')"
        >
          <template #default="scope">

            <el-button
                    size="mini"
                    @click="onView(scope.row)"
            >
              {{ $t('View') }}
            </el-button>
            <el-button
                    :disabled="scope.row.verify == 1"
                    size="mini"
                    @click="pass(scope.row.id)"
            >
              {{ $t('Pass') }}
            </el-button>
            <el-button
                    :disabled="scope.row.verify == 1"
                    size="mini"
                    type="danger"
                    @click="deny(scope.row.id)"
            >
              {{ $t('Deny') }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="text-center">
        <el-pagination
          :current-page="queryForm.page_index"
          :page-sizes="[10, 20, 30, 50]"
          :page-size="queryForm.page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="count"
          @size-change="byPagerSizeChange"
          @current-change="byPagerCurrentChange"
        />
      </div>
    </div>


    <el-dialog
            v-model="dialogVerifyVisible"
            :show-close="false"
            :append-to-body="false"
            :title="$t('View')"
    >
      <div v-if="viewLoading">
        <i class="el-icon-loading" style="margin-left: 100px;font-size: 20px;" ></i>
      </div>
      <el-form
          v-else
              label-position="right"
              label-width="100px"
      >
        <el-form-item label="总价">
          {{ (viewForm.total_price/100.0).toFixed(2)}} 元
        </el-form-item>
        <el-form-item  label="用户">
          [{{getAccountType(viewForm.account_type)}}] {{viewForm.nick}} , {{viewForm.user_mobile}}
        </el-form-item>
        <el-form-item  label="地址">
          {{viewForm.address_info.name}} , {{viewForm.address_info.mobile}} , {{viewForm.address_info.detail}}
        </el-form-item>
        <el-form-item>
          <el-table
                  :data="viewForm.items"
          >
            <el-table-column label="标题">
              <template #default="scope">
              {{scope.row.item_title}},{{scope.row.item_sku_title}}
              </template>
            </el-table-column>
            <el-table-column label="数量">
              <template #default="scope">
              {{scope.row.item_count}}
              </template>
            </el-table-column>
            <el-table-column label="单价">
              <template #default="scope">
              {{(scope.row.item_sku_price/100.0).toFixed(2)}}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>

        <el-form-item
                label="状态日志"
        >
          <el-table
                  :data="viewForm.his"
                  stripe
                  style="width: 100%"
          >
            <el-table-column
                    width="120px"
                    :label="$t('CreateTime')">
              <template #default="scope">
                {{(new Date(scope.row.create_time * 1000)).format('yyyy-MM-dd hh:mm:ss')}}
              </template>
            </el-table-column>
            <el-table-column
                    :label="$t('Note')">
              <template #default="scope">
                {{scope.row.note}}
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
      <template #footer>
        <div class="dialog-footer">
            <el-button
                    size="mini"
                    :loading="loading"
                    @click="dialogVerifyVisible=false"
            >
              {{ $t('Cancel') }}
            </el-button>
            <el-button
                    type="danger"
                    size="mini"
                    :loading="loading"
                    @click="deny(viewForm.evi_id)"
            >
              {{ $t('Deny') }}
            </el-button>
          <el-button
                  size="mini"
                  :loading="loading"
                  @click="pass(viewForm.evi_id)"
          >
            {{ $t('Pass') }}
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import dtOrderApi from '../../api/dtOrderApi'

export default {
  components: {
  },
  data () {
    return {
      dialogVerifyVisible: false,
      viewLoading: true,
      viewForm: {
        address_info: {
          name: '',
          mobile: '',
          detail: ''
        }
      },
      queryForm: {
        order_no: '',
        mobile: '',
        verify_status: '',
        page_index: 1,
        page_size: 10
      },
      rules: {
        title: [
          { required: true, message: this.$i18n.t('Please Input Title'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$i18n.t('String Length Between', [1, 32]), trigger: 'blur' }
        ]
      },
      order_id: '',
      count: 0,
      tableData: [],
      loading: false,
    }
  },
  computed: {},
  watch: {},
  created () {

  },
  mounted () {
    this.refresh()
  },
  methods: {

    getAccountType(aType) {
      switch (aType) {
        case "direct":
          return '直营';
        case "franchise":
          return "加盟";
        default:
          return "其它";
      }
    },
    back() {
      this.dialogVerifyVisible = false;
    },
    onView(row) {
      this.dialogVerifyVisible = true;
      this.viewLoading = true;
      let that = this;
      that.viewForm.evi_id = row.id;
      dtOrderApi.infoEvidence({id: row.order_id}).then((data) => {
        that.viewForm = Object.assign(that.viewForm, data);
        that.viewForm.address_info = JSON.parse(that.viewForm.address_info);
        console.debug(that.viewForm)
      }).catch((msg) => {
        window.tools.alertError(msg);
        that.dialogVerifyVisible = false;
      }).finally(() => {
        that.viewLoading = false;
      });

    },
    pass(id) {
      let that = this;
      dtOrderApi.pass({ id: id }).finally(function () {
        that.dialogVerifyVisible = false;
        that.viewLoading = true;
        that.refresh();
      })
    },
    deny(id) {
      let that = this
      this.$confirm(this.$i18n.t('Action Confirm'), this.$t('Alert'), {
        confirmButtonText: this.$i18n.t('Confirm'),
        cancelButtonText: this.$i18n.t('Cancel'),
        type: 'warning',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            instance.confirmButtonText = window.itboye.vue_instance.$i18n.t('Processing').value
            dtOrderApi.deny({ id: id }).finally(function () {
              instance.confirmButtonLoading = false
              that.dialogVerifyVisible = false;
              that.viewLoading = true;
              that.refresh()
              done()
            })
          } else {
            done()
          }
        }
      }).then(() => {
      }).catch(() => {
      })
    },
    byPagerSizeChange (val) {
      this.queryForm.page_size = val
      this.refresh()
    },
    byPagerCurrentChange (val) {
      this.queryForm.page_index = val
      this.refresh()
    },
    async refresh () {
      // 刷新当前
      this.loading = true
      let that = this
      try {
        let data = await dtOrderApi.queryVerify(that.queryForm);
        console.debug("data", data)
        that.tableData = data.list
        that.count = parseInt(data.count)
        that.loading = false
      } catch (err) {
        console.debug(err)
        window.tools.alertError(err)
        that.loading = false
      }
    },
    onSetOut (row) {
      this.order_id = row.id
      this.user_id = row.user_id
      this.dialogGiveVisible = true
    },
    sureSetOut () {
      this.loading = true
      let that = this
      dtOrderApi.setOut({ id: this.order_id, uid: this.user_id }).catch((err) => {
        tools.alertError(err.message);
      }).then(function () {
        that.refresh()
      }).finally(function () {
          that.loading = false
          that.dialogGiveVisible = false
      })
    }
  }
}
</script>
