/* eslint-disable */
// tools.js 包含工具方法和固定的配置信息, 后期考虑移植配置信息到config.js 中

import * as types from '../../store/mutation-types'

const api_url = process.env.API_ROOT_URI
const picture_url = process.env.PICTURE_UPLOAD_URI
const client_id = process.env.CLIENT_ID

const getRouteMode = () => {
    return process.env.ROUTE_MODE
}
const getVersion = () => {
    return process.env.APP_VERSION
}
const getAppId = () => {
    return getClientId()
}
const clear = () => {
    window.cache.clear()
}

const setClientId = (clientId, expire) => {
    if (!expire) {
        expire = getJwtExpireTime();
        if (expire <= 0) return;
    }
    let expireSec = expire - ((new Date()).getTime() / 1000).toFixed(0);
    expireSec = expireSec > 0 ? expireSec : 0;
    window.cache.setValue('BY_CLIENT_ID', clientId, expireSec)
}

const getClientId = () => {
    let cId = window.cache.getValue('BY_CLIENT_ID')
    if (typeof (cId) === 'undefined' || cId === '') {
        cId = client_id
    }
    setClientId(cId)
    return cId
}
// 设置会话id
const setUID = (uid, expire) => {
    if (!expire) {
        expire = getJwtExpireTime();
        if (expire <= 0) return;
    }
    let expireSec = expire - ((new Date()).getTime() / 1000).toFixed(0);
    expireSec = expireSec > 0 ? expireSec : 0;
    window.cache.setValue('BY_UID', uid, expireSec)
}

// 获取会话id
const getUID = () => {
    let uid = window.cache.getValue('BY_UID')
    // console.debug('getUID', uid)
    if (typeof (uid) === 'undefined' || uid === '') {
        uid = 0
    }
    setUID(uid)
    return parseInt(uid);
}

// 设置昵称
const setNick = (nick, expire) => {
    if (!expire) {
        expire = getJwtExpireTime();
        if (expire <= 0) return;
    }
    let expireSec = expire - ((new Date()).getTime() / 1000).toFixed(0);
    expireSec = expireSec > 0 ? expireSec : 0;
    window.cache.setValue('BY_NICK', nick, expireSec)
}

// 获取昵称
const getNick = () => {
    let nick = window.cache.getValue('BY_NICK')
    if (typeof (nick) === 'undefined' || nick === '') {
        nick = '';
    }
    setNick(nick)
    return nick;
}
// 设置头像地址
const setAvatar = (avatar, expire) => {
    if (!expire) {
        expire = getJwtExpireTime();
        if (expire <= 0) return;
    }
    let expireSec = expire - ((new Date()).getTime() / 1000).toFixed(0);
    expireSec = expireSec > 0 ? expireSec : 0;

    window.cache.setValue('BY_AVATAR', avatar, expireSec)
}

// 获取会话id
const getAvatar = () => {
    var avatar = window.cache.getValue('BY_AVATAR')
    if (typeof (avatar) === 'undefined' || avatar === '') {
        avatar = ''
    }
    setAvatar(avatar)
    return avatar.removeSchema();
}
Date.prototype.getTimestamp = function () {
    return parseInt((this.getTime() / 1000).toFixed(0))
}

// 设置Jwt
const setJwt = (jwt, jwtExpire) => {
    let expireSec = jwtExpire - ((new Date()).getTime() / 1000).toFixed(0);
    expireSec = expireSec > 0 ? expireSec : 0;
    window.cache.setValue('BY_JWT', jwt, expireSec);
    window.cache.setValue('BY_JWT_TIME', jwtExpire, expireSec);
}

const getJwtExpireTime= () => {
    let jwtTime = window.cache.getValue('BY_JWT_TIME')
    if (typeof (jwtTime) === 'undefined' || jwtTime === '') {
        return 0
    }
    return parseInt(jwtTime)
}

// 获取会话id
const getJwt = () => {
    let jwt = window.cache.getValue('BY_JWT')
    if (typeof (jwt) === 'undefined' || jwt === '') {
        return ''
    }
    return jwt
}
// 设置会话id
// 会话ID 必有，随机生成的会缓存 1小时
const setSessionId = (sessionId, expire) => {
    if (!expire) {
        expire = getJwtExpireTime();
        if (expire <= 0) {
            // 默认 1小时
            expire = (new Date()).getTimestamp() + 3600;
        }
    }
    let expireSec = expire - (new Date()).getTimestamp();
    expireSec = expireSec > 0 ? expireSec : 0;

    window.cache.setValue('BY_SESSION_ID', sessionId, expireSec)
}
// 获取会话id
const getSessionId = () => {
    let sessionId = window.cache.getValue('BY_SESSION_ID')
    if (typeof (sessionId) === 'undefined' || sessionId === '') {
        let UUID = require('uuid')
        sessionId = ('DBH' + UUID.v4()).replace(/-/g, '')
        console.debug('generate session id', sessionId)
    }
    setSessionId(sessionId)
    return sessionId
}

const getApiUrl = () => {
    return getCurrentProtocol() + (`${api_url}`).replace('http:', '').replace('https:', '');
}

const getAvatarUploadUrl = () => {
    return getCurrentProtocol() + (`${picture_url}`).replace('http:', '').replace('https:', '')
}

const getImgUrl = (imgUrl) => {
    if (!imgUrl) return ''

    if (!_.startsWith(imgUrl, 'http')) {
        imgUrl = window.tools.getApiUrl() + imgUrl
    }

    return getCurrentProtocol() + imgUrl.replace('http:', '').replace('https:', '')
}
const getCurrentProtocol = () => {
    return window.location.protocol;
}
const getKeyInObject = (object, exp) => {
    if (typeof object === 'undefined') return undefined
    let args = exp.split('.')
    let o = object
    for (let val of args) {
        if (typeof o === 'undefined' || !o.hasOwnProperty(val)) return undefined
        o = o[val]
    }
    return o
}

const returnTop = () => {
    window.scrollBy(0, -100)
    if (document.body.scrollTop > 0) {
        setTimeout(returnTop, 50)
    }
}

const getDeviceType = () => {
    var Sys = {}
    var ua = navigator.userAgent.toLowerCase()
    var s;
    (s = ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1] :
        (s = ua.match(/msie ([\d\.]+)/)) ? Sys.ie = s[1] :
            (s = ua.match(/edge\/([\d\.]+)/)) ? Sys.edge = s[1] :
                (s = ua.match(/firefox\/([\d\.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/(?:opera|opr).([\d\.]+)/)) ? Sys.opera = s[1] :
                        (s = ua.match(/chrome\/([\d\.]+)/)) ? Sys.chrome = s[1] :
                            (s = ua.match(/version\/([\d\.]+).*safari/)) ? Sys.safari = s[1] : 0
    // 根据关系进行判断
    if (Sys.ie) return 'IE'//('IE: ' + Sys.ie);
    if (Sys.edge) return 'EDGE'//('EDGE: ' + Sys.edge);
    if (Sys.firefox) return 'Firefox'//('Firefox: ' + Sys.firefox);
    if (Sys.chrome) return 'Chrome'//('Chrome: ' + Sys.chrome);
    if (Sys.opera) return 'Opera'//('Opera: ' + Sys.opera);
    if (Sys.safari) return 'Safari'// ('Safari: ' + Sys.safari);
    return 'Unkonwn'
}

const getBrowseLanguage = () => {
    return 'zh';
    // let lang = window.cache.getValue('lang')
    // if (!lang) {
    //     if (navigator.language) {
    //         lang = navigator.language//获取浏览器配置语言，支持非IE浏览器
    //         lang = lang.substr(0, 2)//获取浏览器配置语言前两位
    //
    //         let supportLanguages = ['en', 'zh']
    //         let isExists = supportLanguages.some(function (i) {
    //             return (i === lang)
    //         })
    //         if (!isExists) {
    //             lang = 'zh'
    //         }
    //     } else {
    //         lang = 'zh'
    //     }
    // }
    // window.cache.setValue('lang', lang, 24 * 3600)
    // return lang
}

const getTimezone = () => {
    var timezone = (0 - ((new Date()).getTimezoneOffset()) / 60)
    window.cache.setValue('timezone', timezone, 8 * 3600)
    return timezone
}
const debug = (title, value) => {
    console.info("%c " + title + " %c " + value + " ", "background:#606060;padding: 1px; border-radius: 3px 0px 0px 3px;color:#ffffff;", "background:#3474ad;padding: 1px; border-radius: 0px 3px 3px 0px;color:#ffffff;");
}
const setUserSessionData = (userSessionData) => {
    userSessionData = window.tools.base64Utils.encode(JSON.stringify (userSessionData));
    window.cache.setBigDataValue ('BY_USER_SESSION_DATA', userSessionData, 3600);
}
const getUserSessionData = () => {
    let sessionData = window.cache.getBigDataValue ('BY_USER_SESSION_DATA');
    if (!sessionData || sessionData === '') {
        return false;
    }
    try {
        sessionData = JSON.parse(window.tools.base64Utils.decode(sessionData));
        return sessionData;
    } catch (e) {
        console.debug('getUserSessionData', e);
        return false;
    }
};
const isLogin = () => {
    let sessionData = getUserSessionData();
    if (sessionData && sessionData.data && sessionData.data.length > 0 && getJwt()) {
        return true;
    }
    return false;
}

const JSonToCSV = {
    /*
     * obj是一个对象，其中包含有：
     * ## data 是导出的具体数据
     * ## fileName 是导出时保存的文件名称 是string格式
     * ## showLabel 表示是否显示表头 默认显示 是布尔格式
     * ## columns 是表头对象，且title和key必须一一对应，包含有
          title:[], // 表头展示的文字
          key:[], // 获取数据的Key
          formatter: function() // 自定义设置当前数据的 传入(key, value)
     */
    setDataConvert: function(obj) {
        var bw = this.browser();
        if(bw['ie'] < 9) return; // IE9以下的
        var data = obj['data'],
            ShowLabel = typeof obj['showLabel'] === 'undefined' ? true : obj['showLabel'],
            fileName = (obj['fileName'] || 'UserExport') + '.csv',
            columns = obj['columns'] || {
                title: [],
                key: [],
                formatter: undefined
            };
        var ShowLabel = typeof ShowLabel === 'undefined' ? true : ShowLabel;
        var row = "", CSV = '', key;
        // 如果要现实表头文字
        if (ShowLabel) {
            // 如果有传入自定义的表头文字
            if (columns.title.length) {
                columns.title.map(function(n) {
                    row += n + ',';
                });
            } else {
                // 如果没有，就直接取数据第一条的对象的属性
                for (key in data[0]) row += key + ',';
            }
            row = row.slice(0, -1); // 删除最后一个,号，即a,b, => a,b
            CSV += row + '\r\n'; // 添加换行符号
        }
        // 具体的数据处理
        data.map(function(n) {
            row = '';
            // 如果存在自定义key值
            if (columns.key.length) {
                columns.key.map(function(m) {
                    row += '"' + (typeof columns.formatter === 'function' ? columns.formatter(m, n[m]) || n[m] : n[m]) + '",';
                });
            } else {
                for (key in n) {
                    row += '"' + (typeof columns.formatter === 'function' ? columns.formatter(key, n[key]) || n[key] : n[key]) + '",';
                }
            }
            row.slice(0, row.length - 1); // 删除最后一个,
            CSV += row + '\r\n'; // 添加换行符号
        });
        if(!CSV) return;
        this.SaveAs(fileName, CSV);
    },
    SaveAs: function(fileName, csvData) {
        var bw = this.browser();
        if(!bw['edge'] || !bw['ie']) {
            var alink = document.createElement("a");
            alink.id = "linkDwnldLink";
            alink.href = this.getDownloadUrl(csvData);
            document.body.appendChild(alink);
            var linkDom = document.getElementById('linkDwnldLink');
            linkDom.setAttribute('download', fileName);
            linkDom.click();
            document.body.removeChild(linkDom);
        }
        else if(bw['ie'] >= 10 || bw['edge'] == 'edge') {
            var _utf = "\uFEFF";
            var _csvData = new Blob([_utf + csvData], {
                type: 'text/csv'
            });
            navigator.msSaveBlob(_csvData, fileName);
        }
        else {
            var oWin = window.top.open("about:blank", "_blank");
            oWin.document.write('sep=,\r\n' + csvData);
            oWin.document.close();
            oWin.document.execCommand('SaveAs', true, fileName);
            oWin.close();
        }
    },
    getDownloadUrl: function(csvData) {
        var _utf = "\uFEFF"; // 为了使Excel以utf-8的编码模式，同时也是解决中文乱码的问题
        if (window.Blob && window.URL && window.URL.createObjectURL) {
            var csvData = new Blob([_utf + csvData], {
                type: 'text/csv'
            });
            return URL.createObjectURL(csvData);
        }
        // return 'data:attachment/csv;charset=utf-8,' + _utf + encodeURIComponent(csvData);
    },
    browser: function() {
        var Sys = {};
        var ua = navigator.userAgent.toLowerCase();
        var s;
        (s = ua.indexOf('edge') !== - 1 ? Sys.edge = 'edge' : ua.match(/rv:([\d.]+)\) like gecko/)) ? Sys.ie = s[1]:
            (s = ua.match(/msie ([\d.]+)/)) ? Sys.ie = s[1] :
                (s = ua.match(/firefox\/([\d.]+)/)) ? Sys.firefox = s[1] :
                    (s = ua.match(/chrome\/([\d.]+)/)) ? Sys.chrome = s[1] :
                        (s = ua.match(/opera.([\d.]+)/)) ? Sys.opera = s[1] :
                            (s = ua.match(/version\/([\d.]+).*safari/)) ? Sys.safari = s[1] : 0;
        return Sys;
    }
};
// 测试
// JSonToCSV.setDataConvert({
//     data: [
//         {name: '张三', amont: '323433.56', proportion: 33.4},
//         {name: '李四', amont: '545234.43', proportion: 55.45}
//     ],
//     fileName: 'test',
//     columns: {
//         title: ['姓名', '金额', '占比'],
//         key: ['name', 'amont', 'proportion'],
//         formatter: function(n, v) {
//             if(n === 'amont' && !isNaN(Number(v))) {
//                 v = v + '';
//                 v = v.split('.');
//                 v[0] = v[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
//                 return v.join('.');
//             }
//             if(n === 'proportion') return v + '%';
//         }
//     }
// });
const tools = {
    JSonToCSV,
    debug,
    setUserSessionData,
    getUserSessionData,
    getRouteMode,
    getImgUrl,
    getBrowseLanguage,
    getTimezone,
    clear,
    getDeviceType,
    getUID, setUID,
    getNick,setNick,
    getClientId, setClientId,
    getAvatar, setAvatar,
    getVersion, getApiUrl, getAvatarUploadUrl, getKeyInObject, returnTop, getAppId,
	getSessionId,
	getJwt, setJwt, getJwtExpireTime,
    isLogin,
}
String.prototype.trim = function (char, type) {
    if (char) {
        if (type == 'left') {
            return this.replace(new RegExp('^\\' + char + '+', 'g'), '')
        } else if (type == 'right') {
            return this.replace(new RegExp('\\' + char + '+$', 'g'), '')
        }
        return this.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '')
    }
    return this.replace(/^\s+|\s+$/g, '')
}

// 移除 removeSchema
String.prototype.removeSchema = function() {
    return this.replace("http:", "").replace("https:", "");
}

Date.prototype.format = function (fmt) {
    var o = {
        'M+': this.getMonth() + 1,                 //月份
        'd+': this.getDate(),                    //日
        'h+': this.getHours(),                   //小时
        'm+': this.getMinutes(),                 //分
        's+': this.getSeconds(),                 //秒
        'q+': Math.floor((this.getMonth() + 3) / 3), //季度
        'S': this.getMilliseconds()             //毫秒
    }
    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length))
    }
    for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
        }
    }
    return fmt
}

export default tools
