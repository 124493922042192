'use strict';

exports.__esModule = true;
exports.default = {
	'English': 'English',
	'中文简体': '中文简体',
	'Unit': '单位',
	'Pass': '通过',
	'Deny': '驳回',
	'支付审核': '支付审核',
	'Wallet': '钱包',
	'RechargeRebate': '充值返利',
	'RechargeOrder': '充值订单',
	'Batch':'批量',
	'Exception': '异常',
	'Message':'消息',
	'WapAliPay': '支付宝(WAP)',
	'UserAgent': '用户终端',
	'PageNo':'页码',
	'SourceTypeName': '来源类别名称',
	'Subregion': '子级区域',
	'Pinyin': '拼音',
	'Telephone': '电话区号',
	'Region': '区域',
	'PaymentChannel': '支付通道',
	'Pay': '支付',
	'Config': '配置',
	'AppId': 'App Id',
	'UserPrivateKey': '用户私钥',
	'TradeStatus': '交易状态',
	'OrderNo': '订单编号',
	'OrderType': '订单分类',
	'Subject': '子项',
	'AliPay': '支付宝',
	'Wait': '等待',
	'Information': '信息',
	'Card': '卡',
	'Reason': '理由',
	'Extra': '额外',
	'Username': '用户名',
	'Rejected': '驳回',
	'Recharge': '充值',
	'NotReviewed':'待审核',
	'Passed':'通过',
	'Fee': '手续费',
	'Account': '帐户',
	'Amount': '金额',
	'Withdrawal': '提现',
	'Order': '订单',
	'AliPublicKey': '系统公钥',
	'UserPublicKey': '用户私钥',
	'Mode': '模式',
	'ReturnUrl': '跳转地址',
	'NotifyUrl': '异步通知地址',
	'CrawlingFirst': '优先爬取',
	'Operator': '操作',
	'Reply': '回复',
	'FriendshipLinks': '友情链接',
	'Suggest': '意见反馈',
	'Client': '应用',
	'Project': '项目',
	'Send': '发送',
	'Log': '日志',
	'Session': '会话',
	'Device': '设备',
	'DeviceType': '设备类型',
	'ExpireTime': '过期时间',
	'Profile':'个人信息',
	'2StepVerify': '2步验证',
	'Nickname': '昵称',
	'RePassword': '重复密码',
	'Email': 'Email',
	'Ip': 'IP',
	'RegTime': '注册时间',
	'RegIp': '注册IP',
	'LastLoginIp': '最近登录IP',
	'LastLoginTime': '最近登录时间',
	'NoCover': '无封面',
	'Index': '首页',
	'Set': '设置',
	'State': '状态',
	'Recommend': '推荐',
	'Writing': '连载',
	'WritingEnd': '完结',
	'Book': '书籍',
	'BookIndex': '书籍管理',
	'Info': '信息',
	'BookSource': '书籍来源',
	'NextCrawlingTime': '下次更新时间',
	'source_type_name': '来源',
	'StartUrl': '起始链接',
	'CurrentUrl': '当前链接',
	'Chapter': '章节',

	'Is': '是否',
	'0': '否',
	'1': '是',
	'Publish': '发布',
	'No':'否',
	'Yes': '是',
	'Success': '成功',
	'Home': '主页',
	'Loading': '加载中...',
	'Processing': '执行中...',
	'Please Login Again': '请重新登录',
	'Please Try Again': '请重试',
	'Logout': '安全退出',
	'Login': '登录',
	'Confirm': '确定',
	'Cancel': '取消',
	'Refresh': '刷新',
	'Add': '添加',
	'Update': '更新',
	'Delete': '删除',
	'Edit': '编辑',
	'Search': '查询',
	'Sort': '排序',
	'Title': '标题',
	'Name': '名称',
	'Date': '日期',
	'Time': '时间',
	'Times': '次数',
	'ClientId': '应用ID',
	'Response Error': '响应信息错误',
	'Today': '今天',
	'Yesterday': '昨天',
	'String Length Between': '字符长度大于 {0} 且小于 {1}',
	'Normal Level': '普通级别',
	'System Level': '系统级别',
	'Level': '级别',
	'Note': '备注',
	'Code': '编码',
	'Selection': '选择项',
	'Node': '节点',
	'Tip': '提示',
	'Alert': '警告',
	'Datatree': '数据字典',
	'Current Data': '当前数据',
	'Label': '标签',
	'Alias': '别名',
	'Root': '根',
	'Save': '保存',
	'Reset': '重置',
	'Action': '操作',
	'Roles': '角色',
	'ID': 'ID',
	'Enable': '启用',
	'Disable': '禁用',
	'Policy': '策略',
	'Menu': '菜单',
	'Back': '后退',
	'SelectItems': '请先选择',
	'Checked': '选中项',
	'Unchecked': '未选中项',
	'Version': '版本',
	'Statements': '语句',
	'Remove': '移除',
	'PrefixOfPhone': '电话区号',
	'Mobile': '手机号',
	'User': '用户',
	'Nick': '昵称',
	'Notification': '通知',
	'System': '系统',
	'Summary': '摘要',
	'Read': '已读',
	'Unread': '未读',
	'View': '查看',
	'Number': '数值',
	'Char': '单字符',
	'Text': '字符串',
	'Array': '数组',
	'Enum': '枚举',
	'ValueType': '值类型',
	'Value': '值',
	'Category': '类别',
	'Append': '追加',
	'Icon': '小图标',
	'Url': '链接',
	'Password': '密码',
	'Old_Password': '旧密码',
	'New_Password': '新密码',
	'ModifyPassword': '修改密码',
	'Confirm_Password': '确认密码',
	'Drag': '拖动',
	'File': '文件',
	'Or': '或',
	'Click': '点击',
	'Upload': '上传',
	'Remark': '备注',
	'CreateTime': '创建时间',
	'Album': '相册',
	'Source': '来源',
	'Description': '描述',
	'Total': '总数',
	'Internet': '互联网',
	'Original': '原创',
	'Tag': '标签',
	'Photo': '照片',
	'Image': '图片',
	'Banner': '横幅',
	'OneYear': '一年',
	'ThreeYear': '三年',
	'CreatedBy': '创建者',
	'JumpUrl': '跳转地址',
	'JumpType': '跳转地址类型',
	'Position': '位置',
	'DateRange': '日期范围',
	'StartDate': '开始日期',
	'EndDate': '结束日期',
	'Showing': '显示时间',
	'Width': '宽',
	'Height': '高',
	'Status': '状态',
	'Cover': '封面',
	'Article': '文章',
	'All': '全部',
	'Author': '作者',
	'Cms': '文章',
	'Content': '内容',
	'Leaf': '叶子节点',
	'Shop': '商城',
	'Attribute': '属性',
	'single': '单选',
	'multiple': '多选',
	'Relate': '关联',
	'Property': '属性',
	'Brand': '品牌',
	'Payment': '支付',
	'PayOrder': '支付订单',
	'PayCode': '支付编号',
	'OrderCode': '订单编号',
	'PayTime': '支付时间',
	'Merchant': '商户',
	'PayType': '支付类型',
	'Money': '金额',
	'PayStatus': '支付状态',
	'Paid': '已支付',
	'NotPaid': '未支付',
	'Initiate': '发起',
	'CallbackUrl': '回调地址',
	'Channel': '渠道',
	'TradeNo': '交易号',
	'NotifyTime': '回调时间',
	'Notify': '通知',
	'History': '历史',
	'Count': '次数',
	'Last': '最近',
	'Next': '下一个',
	'Detail': '详情',
	'Scene': '场景',
	'Video': '视频',
	'VideoCate': '视频分类',
	'VideoType': '视频源类型',
	'VideoUri': '视频播放源',
	'ComeFrom': '来源',
	'OffShelves': '下架',
	'OnShelves': '上架',
	'Year': '年份',
	'Actor': '演员',
	'Director': '导演',
	'End': '完结',
	'Language': '语言',
	'Area': '地区',
	'Goods': '商品',
	'GoodsNo': '商品货号',
	'SubTitle': '子标题',
	'Price': '价格',
	'Absolute': '绝对',
	'SaleTime': '销售时间',
	'Prev': '上一个',
	'Place': '地址',
	'Freight': '运费',
	'SupportServices': '支持服务',
	'SmallCover': '小尺寸封面',
	'SKU': '库存规格',
	'Delivery': '发货',
	'Stock': '库存',
	'Key': '关键',
	'Weight': '重量',
	'Volume': '体积',
	'More': '更多',
	'Province': '省',
	'City': '市',
	'Town': '县/镇',
	'Template': '模板',
	'LogisticsType': '运送方式',
	'Method': '计算方式',
	'UpdateTime': '更新时间',
	'FreeShipping': '包邮',

	// 短语
	'PropertyValue': '属性值',
	'ColorProperty': '颜色属性',
	'SaleProperty': '销售属性',
	'PropertyType': '类型',
	'PublicKey': '公钥',
	'SecretKey': '私钥',
	'MustBeNumber': '必须是一个数值',
	'WelcomeToUse': '欢迎您使用本系统!',
	'Action Confirm': '操作确定?',
	'Please Input': '请输入',
	'Please Input {0}': '请输入{0}',
	'Please Input Title': '请输入标题',
	'Please Input Alias': '请输入别名',
	'Please Input Name': '请输入名称',
	'Please Input Statements': '请输入策略语句',
	'JsonFormatString': '请输入json格式的字符串',
	'Please Input Password': '请输入密码',
	'FileTypeLimitJpg': '上传头像图片只能是 JPG 格式!',
	'FileTypeLimitJpgPng': '上传头像图片只能是 JPG或PNG 格式!',
	'FileSizeLimit2MB': '上传头像图片大小不能超过 2MB!',

	// 长整句话
	'DatatreeeDescription': '1. 根节点 可以点击来加载一级数据 2. 编辑只能编辑第一个',
	'Two_Password_Not_Match': '新密码和确认密码不一致',

	// 服务器返回的固定数据
	'DataDictionary': '数据字典',
	'ApiLog': '接口调用日志',
	'Clients': '应用管理',
	'SystemSettings': '系统配置',
	'Avatar': '头像',

	// 应用
	'ClientName': '应用名称',
	'ClientSecret': '应用密钥',
	'ClientTotalLimit': '接口总调用次数',
	'ClientDayLimit': '接口每日调用次数',
	'ClientAlg': '通信算法',

	// 文章
	'draft': '草稿',
	'CmsArticle.draft': '草稿',
	'CmsArticle.published': '已发布',

	// Unit
	'Unit.Yuan': '元',

	// PayType
	'PcAliPay': '支付宝-电脑端',

	// 商品状态
	'DtGoods.on': '上架',
	'DtGoods.off': '下架',

	//优惠券
	'DiscountAmount': '抵扣金额',
	'CondAmount': '条件金额',
	'Give': '赠送券',
	'GetTime': '领取时间',

	//自提点
	'Lng': '经度',
	'Lat': '纬度',
	'ContactPhone': '联系电话',
	'NoPickedUp': '待送出',
	'PickedUp': '已取货',
	'Canceled': '已取消',
	'Sent': '已送出',
	'PickupCode': '取货码',

	'otherReason': '其他原因',
	'BookingDays': '可提前几小时',

	'UsedTime': '使用时间',
	'SmallImage': '小图',
	'DisplayOrder': '排序序号',
	'Coupon': '优惠券',
	'PickUpLocation': '自提点',
	'Location': '定位',
	'WxappMini':'小程序支付',
	'Fake': '模拟支付',
	'Diet': '餐饮',
	'Complaints': '投诉',
	'SimpleShop': '简易商店',
	'OrderStatus': '订单状态',
	'GoodsId': '商品ID',
};
